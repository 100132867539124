@import "_vars";

// обновлять версию при изменении иконок
@import url('/wp-content/themes/ed_blog/font/ed-icons/style.css?v=15');

@import url('/wp-content/themes/ed_blog/font/koko-icons/style.css?v=22');

@font-face {
    font-family: 'Euclid Flex B2';
    src: url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Regular.eot');
    src: local('Euclid Flex B'), local('EuclidFlexB-Regular'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Regular.eot?#iefix') format('embedded-opentype'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Regular.woff2') format('woff2'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Regular.woff') format('woff'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Regular.ttf') format('truetype'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Regular.svg#EuclidFlexB-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Flex B2';
    src: url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Medium.eot');
    src: local('Euclid Flex B Medium'), local('EuclidFlexB-Medium'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Medium.eot?#iefix') format('embedded-opentype'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Medium.woff2') format('woff2'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Medium.woff') format('woff'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Medium.ttf') format('truetype'),
    url('/wp-content/themes/ed_blog/font/EuclidFlexB2/subset-EuclidFlexB-Medium.svg#EuclidFlexB-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Firenight';
    src: url('/wp-content/themes/ed_blog/font/Firenight/subset-Firenight-Regular.eot');
    src: url('/wp-content/themes/ed_blog/font/Firenight/subset-Firenight-Regular.eot?#iefix') format('embedded-opentype'),
    url('/wp-content/themes/ed_blog/font/Firenight/subset-Firenight-Regular.woff2') format('woff2'),
    url('/wp-content/themes/ed_blog/font/Firenight/subset-Firenight-Regular.woff') format('woff'),
    url('/wp-content/themes/ed_blog/font/Firenight/subset-Firenight-Regular.ttf') format('truetype'),
    url('/wp-content/themes/ed_blog/font/Firenight/subset-Firenight-Regular.svg#Firenight-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: currency-icons;
    src: url('/wp-content/themes/ed_blog/font/currency-icons.eot');
    src: url('/wp-content/themes/ed_blog/font/currency-icons.eot?#iefix') format("eot"),
    url('/wp-content/themes/ed_blog/font/currency-icons.woff2') format("woff2"),
    url('/wp-content/themes/ed_blog/font/currency-icons.woff') format("woff"),
    url('/wp-content/themes/ed_blog/font/currency-icons.ttf') format("truetype"),
    url('/wp-content/themes/ed_blog/font/currency-icons.svg#currency-icons') format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: currency-icons;
    src: url('/wp-content/themes/ed_blog/font/currency-icons-bold.eot');
    src: url('/wp-content/themes/ed_blog/font/currency-icons-bold.eot?#iefix') format("eot"),
    url('/wp-content/themes/ed_blog/font/currency-icons-bold.woff2') format("woff2"),
    url('/wp-content/themes/ed_blog/font/currency-icons-bold.woff') format("woff"),
    url('/wp-content/themes/ed_blog/font/currency-icons-bold.ttf') format("truetype"),
    url('/wp-content/themes/ed_blog/font/currency-icons-bold.svg#currency-icons-bold') format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

.CurrencyIcon {
    display: inline-block;
    font-family: currency-icons;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    font-size: .8em;
    line-height: 1;
    font-weight: inherit;
    letter-spacing: -.1em;
}

.CurrencyIcon--rub:before {
    content: "\EA01";
}

.ico {
    font-family: $font_icons !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
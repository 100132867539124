$wide_25k: 2560px;
$wide_2k: 1920px;
$wide: 1600px;

$middle: 1400px;

$bootstrap_xl: 1200px;
$bootstrap_lg: 992px;
$bootstrap_md: 768px;
$bootstrap_sm: 576px;

$desktop: 1280px;

$tablet: 1024px;
$tablet_vert: 768px;

$tel_max: 414px;
$tel_lg: 375px;
$tel_sm: 360px;
$tel_xs: 320px;
$tel_xxs: 280px;

$tel_home: 550px;

@mixin media_middle {
    @media only screen and (max-width: $middle) {
        @content;
    }
}

@mixin media_height_more_1080 {
    @media only screen and (min-height: 1081px) {
        @content;
    }
}

@mixin media_pc {
    @media only screen and (min-width: $tablet) {
        @content;
    }
}

@mixin media_tel_landscape {
    @media only screen and (max-height: 768px) {
        @content;
    }
}

@mixin media_2k {
    @media only screen and (max-width: $wide_2k) {
        @content;
    }
}

@mixin media_desktop {
    @media only screen and (max-width: $wide - 1) {
        @content;
    }
}

@mixin media_tablet {
    @media only screen and (max-width: $desktop - 1) {
        @content;
    }
}

@mixin media_tablet_bootstrap {
    @media only screen and (max-width: $bootstrap_xl - 1) {
        @content;
    }
}

@mixin media_tablet_bootstrap_only {
    @media only screen and (max-width: $bootstrap_xl - 1) and (min-width: $bootstrap_lg) {
        @content;
    }
}

@mixin media_tablet_vert_bootstrap {
    @media only screen and (max-width: $bootstrap_lg - 1) {
        @content;
    }
}

@mixin media_tablet_vert_bootstrap_only {
    @media only screen and (max-width: $bootstrap_lg - 1) and (min-width: $bootstrap_md) {
        @content;
    }
}

@mixin media_up_to_tablet_inc {
    @media only screen and (max-width: $tablet) {
        @content;
    }
}

@mixin media_tablet_vert {
    @media only screen and (max-width: $tablet - 1) {
        @content;
    }
}

@mixin media_only_tablet_vert {
    @media only screen and (max-width: $tablet - 1) and (min-width: $tablet_vert) {
        @content;
    }
}

@mixin media_exactly_tablet_vert {
    @media only screen and (max-width: $tablet_vert) and (min-width: $tablet_vert) {
        @content;
    }
}

@mixin media_tel {
    @media only screen and (max-width: $tablet_vert - 1) {
        @content;
    }
}

@mixin media_tel_home {
    @media only screen and (max-width: $tel_home) {
        @content;
    }
}

@mixin media_tel_lg {
    @media only screen and (max-width: $tel_max - 1) {
        @content;
    }
}

@mixin media_tel_sm {
    @media only screen and (max-width: $tel_lg - 1) {
        @content;
    }
}

@mixin media_tel_xs {
    @media only screen and (max-width: $tel_sm - 1) {
        @content;
    }
}

@mixin media_tel_xxs {
    @media only screen and (max-width: $tel_xs - 1) {
        @content;
    }
}

@mixin media_more_than_tel {
    @media only screen and (min-width: $tablet_vert) {
        @content;
    }
}

@mixin media_more_than_tablet_vert {
    @media only screen and (min-width: $bootstrap_lg) {
        @content;
    }
}

@mixin media_more_than_tablet {
    @media only screen and (min-width: $bootstrap_xl) {
        @content;
    }
}
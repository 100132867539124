@import "_vars";
@import "mix";
@import "responsive";

#wrapper {
    padding-top: 210px;

    @include media_tablet_vert_bootstrap {
        padding-top: 96px;
    }

    @include media_tel {
        padding-top: 130px;
    }

    @include media_tel_xs {
        padding-top: 120px;
    }
}

.header-block {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 550;
    background-color: $white;
    box-shadow: 0 10px 20px rgba(92, 89, 89, 0.04);
    @include ff(16);

    a {
        text-decoration: none;
    }

    .button-back {
        display: none;
    }

    .header-logo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        img {
            display: block;
            width: 186px;
            height: auto;

            @include media_tel {
                width: 130px;
            }

            @include media_tel_xs {
                width: 120px;
            }

            &.header-logo__short {
                display: none;
            }
        }
    }

    &__top {
        padding-top: 20px;
        position: relative;
        z-index: 200;

        .size-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-left, &-right {
            display: flex;
            align-items: center;
        }

        &-logo {
            margin-right: 44px;
            @include flexSize(188px);

            @include media_tablet_bootstrap {
                margin-right: 26px;
                @include flexSizeChange(144px);
            }
        }

        &-menu {
            ul {
                @include clearList;

                ul {
                    position: absolute;
                    top: 100%;
                    left: -30px;
                    background-color: $white;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
                    border-radius: 6px;
                    padding: 20px 28px;
                    opacity: 0;
                    visibility: hidden;

                    li {
                        & + li {
                            margin-top: 12px;
                        }
                    }
                }

                li {
                    position: relative;
                }
            }

            & > ul {
                display: flex;
                align-items: flex-start;

                & > li {
                    & + li {
                        margin-left: 14px;
                    }

                    & > a {
                        padding: 10px 5px;
                    }

                    &.active {
                        & > a {
                            color: $color_grey_light;
                        }
                    }
                }
            }

            .parent {
                & > a {
                    span {
                        display: inline-block;
                        position: relative;
                        padding-right: 18px;

                        &:after {
                            content: '\e90c';
                            position: absolute;
                            right: 0;
                            top: 8px;
                            transition: transform 0.15s ease-out;
                            @include koko;
                            @include fi(8);
                        }
                    }
                }

                &:hover {
                    & > a {
                        color: $color_primary_hover;

                        span:after {
                            transform: rotate(-180deg);
                            transition: transform 0.15s ease-in;
                        }
                    }

                    & > ul {
                        opacity: 1;
                        visibility: visible;
                        transition: opacity 0.2s ease;
                    }
                }
            }
        }

        &-coins {
            margin-left: 28px;
            min-width: 62px;
            display: flex;
            justify-content: flex-end;

            @include media_tablet_bootstrap {
                margin-left: 14px;
            }
        }
    }

    &__mid {
        padding: 20px 0;
        position: relative;
        z-index: 100;

        @include media_tel {
            padding: 12px 0 16px;
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media_tel {
                flex-wrap: wrap;
            }

            & > * + * {
                margin-left: 8px;
            }
        }

        &-logo {
            display: none;
            //@include flexSize(186px);

            @include media_tablet_vert_bootstrap {
                display: block;
            }

            @include media_tel {
                //@include flexSizeChange(130px);
            }

            @include media_tel_xs {
                //@include flexSizeChange(120px);
            }
        }

        &-catalog {
            margin-left: 0;

            @include media_tablet_vert_bootstrap {
                display: none;
            }
        }

        &-search {
            flex-grow: 1;

            @include media_tablet_vert_bootstrap {
                display: none;
            }
        }

        &-delivery {
            min-width: 228px;
            max-width: 300px;

            @include media_tablet_vert_bootstrap {
                flex-grow: 1;
                margin-left: 16px;
                min-width: auto;
                max-width: none;
            }

            @include media_tel {
                order: 3;
                flex-basis: 100%;
                margin: 12px 0 0;
            }
        }

        &-buttons {
            display: flex;

            @include media_tablet_vert_bootstrap {
                margin-left: 0;
            }

            & > * + * {
                margin-left: 8px;

                @include media_tablet_vert_bootstrap {
                    margin-left: 16px;
                }

                @include media_tel {
                    margin-left: 8px;
                }

                @include media_tel_xs {
                    margin-left: 4px;
                }
            }

            .btn {
                display: flex;

                &.btn-open-cart {
                    @include media_tablet_vert_bootstrap {
                        display: none;
                    }
                }

                &.btn-open-search {
                    display: none;

                    @include media_tablet_vert_bootstrap {
                        display: flex;
                    }
                }
            }
        }
    }

    &__bot {
        position: relative;
        padding-bottom: 20px;
        z-index: 50;
    }

    &__top, &__bot {
        @include media_tablet_vert_bootstrap {
            display: none;
        }
    }

    &__mobile-search {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: -20px;
        border-bottom: 1px solid $color_border;
        background-color: $white;
        z-index: 110;

        @include media_more_than_tablet_vert {
            display: none !important;
        }

        @include media_tel {
            margin: 0;
            top: 52px;
        }

        &.active {
            display: block;
        }
    }

    &.scroll {
        .header-block__top, .header-block__bot {
            display: none;
        }

        @include media_tel {
            .header-block__mid-container {
                flex-wrap: nowrap;
            }

            .header-block__mid-logo {
                min-width: 24px;
                @include flexReset;

                .header-logo__full {
                    display: none;
                }

                .header-logo__short {
                    display: block;
                    width: 32px;

                    @include media_tel_xs {
                        width: 24px;
                    }
                }
            }

            .header-block__mid-delivery {
                order: 0;
                flex-basis: auto;
                margin: 0 0 0 8px;

                @include media_tel_xs {
                    margin-left: 4px;
                }
            }

            .header-block__mid-buttons {
                margin-left: 0;
            }

            .btn-open-wish {
                display: none;
            }

            .delivery-type {
                padding: 8px 16px 8px 12px;

                @include media_tel_xs {
                    padding-left: 4px;
                    padding-right: 8px;
                }

                &__icon {
                    margin-right: 4px;
                }

                &__title {
                    @include ff(12);
                }
            }

            .btn-icon-extra {
                @include media_tel_xxs {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
}

body:not(.common-home) .header-block.scroll {
    @include media_tablet_vert_bootstrap {
        .header-logo {
            display: none;
        }

        .button-back {
            display: block;
        }
    }
}

.search-container {
    position: relative;

    .form-box {
        &:before {
            content: '\e912';
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            color: $color_grey_light;
            transition: all 0.15s ease-in-out;
            @include koko;
            @include fi(24);
        }

        input {
            padding-left: 48px;
            padding-right: 144px;
        }
    }

    &__clear {
        color: inherit;
        text-decoration: none;
        right: 120px;
        @include koko;
        @include fi(16);

        &:before {
            content: '\e911';
        }
    }

    button {
        right: 20px;
    }

    &__clear, button {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &.active {
        .form-box:before {
            color: $font_color;
            transition: all 0.15s ease-in-out;
        }

        .search-container__clear, button {
            display: block;
        }
    }

    &-mobile {
        .form-box {
            padding-right: 66px;

            @include media_tel {
                padding-right: 46px;
            }

            &:before {
                display: none;
            }

            input {
                background-color: $white !important;
                border: none;
                border-radius: 0;
                padding: 14px 0 14px 30px;

                @include media_tel {
                    padding-left: 24px;
                }

                @include media_tel_lg {
                    padding-left: 15px;
                }
            }
        }

        .search-container__clear {
            color: $color_grey_light !important;
            right: 0;
            height: 100%;
            width: 66px;
            padding-right: 30px;
            align-items: center;
            justify-content: flex-end;

            @include media_tel {
                width: 46px;
                padding-right: 24px;
            }

            @include media_tel_lg {
                padding-right: 15px;
            }
        }

        &.active .search-container__clear {
            display: flex !important;
        }
    }
}

.header-catalog {
    position: fixed;
    left: 0;
    width: 100%;
    top: 134px;
    z-index: 560;
    padding-top: 20px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(25px);

    .header-block.scroll + & {
        top: 74px;
    }

    @include media_tablet_vert_bootstrap {
        display: none;
    }

    &:hover, &.active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
        transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    }

    &__container {
        border-top: 1px solid $color_border;
        background-color: $white;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
        height: 480px;
    }

    a {
        text-decoration: none;
    }

    ul {
        @include clearList;
    }

    &__outer {
        display: flex;
    }

    &__left {
        border-right: 1px solid $color_border;
        padding: 34px 6px 0 0;
        position: relative;
        @include flexSize(294px);

        &-scroll {
            padding-right: 20px;

            & > ul {
                padding-bottom: 34px;
            }
        }

        ul {
            & > li {
                position: static;

                & + li {
                    margin-top: 24px;
                }

                & > a {
                    display: block;
                    padding-right: 20px;
                    position: relative;
                    @include ff(16, 500);

                    &:after {
                        content: '\e916';
                        position: absolute;
                        right: 0;
                        top: 6px;
                        @include koko;
                        @include fi(12);
                    }
                }

                &.active > a {
                    color: $color_primary_hover;
                }
            }
        }
    }

    &__right {
        flex-grow: 1;
        padding: 26px 0 0 48px;

        @include media_tablet_bootstrap {
            padding-left: 18px;
        }

        &-scroll {
            padding-right: 12px;
        }
    }

    &__left, &__right {
        &-scroll {
            height: 100%;
            position: relative;
            overflow-y: auto;
            @include scroll;
        }
    }

    &__inner {
        display: none;

        &.active {
            display: block;
        }

        &-title {
            @include ff(24, 500);
        }

        &-list {
            margin-top: 24px;
            @include columns(3);

            & > ul {
                padding-bottom: 38px;

                & > li {
                    & > a {
                        @include ff(16, 500);
                    }

                    & > ul {
                        margin-top: 16px;

                        & > li {
                            & + li {
                                margin-top: 12px;
                            }

                            & > a {
                                @include ff(12);
                            }
                        }
                    }
                }
            }
        }
    }
}

.delivery-type {
    @extend .btn, .btn-koko;
    border: none;
    cursor: pointer;
    padding: 10px 22px 10px 20px;
    white-space: nowrap;
    position: relative;
    height: 56px;

    @include media_tablet_bootstrap_only {
        padding-left: 12px;
    }

    @include media_tel {
        height: auto;
        padding: 11px 26px 11px 24px;
    }

    @include media_tel_lg {
        padding: 11px 18px 11px 16px;
    }

    @include media_tel_xs {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 100%;

        &:after {
            content: '\e90c';
            margin-left: 14px;
            @include koko;
            @include fi(8);

            @include media_tel_xs {
                margin-left: 5px;
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        i {
            @include fi(24);
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            color: $white;
            border-radius: 4px;
            background-color: $color_black;
            @include ff(12);
        }
    }

    &__desc {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        @include ff(16, 400);
    }

    &__text {
        color: $color_primary_dark;
        @include ff(10);
    }

    &__title, &__text {
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-disabled {
        background-color: $white;
        border-color: $color_border;

        .delivery-type__icon i, .delivery-type__text {
            color: $color_grey_light;
        }
    }
}
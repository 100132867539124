@use "sass:math";
@import "_vars";

@mixin clearfix() {
    &:before, &:after {
        display: table;
        content: '';
    }
    &:after {
        clear: both;
    }
}

@mixin clearList() {
    overflow: visible;

    &, ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        &:before {
            display: none;
        }
    }
}

@mixin square {
    &:before {
        content: '';
        padding-top: 100%;
        float: left;
    }
}

@mixin fi($input, $important: null) {
    @if ($important != null) {
        font-size: $input * 1px !important;
        line-height: $input * 1px !important;
    } @else {
        font-size: $input * 1px;
        line-height: $input * 1px;
    }
}

@mixin inheritLink {
    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: $color_primary_hover;
        }

        &:active {
            color: $color_primary_active;
        }
    }
}

@mixin linkHover {
    &:hover {
        color: $color_primary_hover;
    }

    &:active {
        color: $color_primary_active;
    }
}

@mixin ff($input_font-size, $weight: null, $input_line-height: null) {
    font-size: $input_font-size * 1px;

    @if (($input_font-size != 15 and $input_font-size != 18) and ($input_font-size < 24)) {
        line-height: ($input_font-size + 4) * 1px;
    } @else if ($input_font-size == 15) {
        line-height: ($input_font-size + 5) * 1px;
    } @else if ($input_font-size == 18 or $input_font-size >= 24) {
        line-height: ($input_font-size + 6) * 1px;
    }

    @if ($weight != null and $weight != '') {
        font-weight: $weight;
    }

    @if ($input_line-height != null) {
        line-height: $input_line-height * 1px;
    }
}

@mixin columns($cols: null, $gap: null) {
    @if ($cols != null) {
        column-count: $cols;
    }

    @if ($gap != null) {
        column-gap: $gap * 1px;
    }

    & > * {
        display: block;
        break-inside: avoid-column;
        page-break-inside: avoid;
    }
}

@mixin clearColumns() {
    column-count: 1;
    column-gap: 0;

    & > * {
        float: none;
    }
}

@mixin iconFont() {
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin ico() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font_icons !important;

    @include iconFont();
}

@mixin koko() {
    font-family: $font_koko !important;

    @include iconFont();
}

@mixin fixRadiusEffect() {
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

@mixin flexSize($input: null) {
    @if ($input != null) {
        flex: 0 0 $input;
        max-width: $input;
        width: $input;
    }
}

@mixin flexSizeVert($input: null) {
    @if ($input != null) {
        flex: 0 0 $input;
        max-height: $input;
        height: $input;
    }
}

@mixin flexReset() {
    flex: 0 1 auto;
    max-width: none;
    width: auto;
}

@mixin flexResetVert() {
    flex: 0 1 auto;
    max-height: none;
    height: auto;
}

@mixin flexSizeChange($input: null) {
    @if ($input != null) {
        flex-basis: $input;
        max-width: $input;
        width: $input;
    }
}

@mixin flexSizeChangeVert($input: null) {
    @if ($input != null) {
        flex-basis: $input;
        max-height: $input;
        height: $input;
    }
}

@mixin scroll($marginRight: null, $paddingRight: null) {
    @if ($marginRight != null) {
        margin-right: $marginRight * 1px;
    }

    @if ($paddingRight != null) {
        padding-right: $paddingRight * 1px;
    }

    &::-webkit-scrollbar {
        background: transparent;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color_border;
        border-radius: 3px;
        width: 6px;
        height: 50px;

        &:hover {
            background-color: $color_primary_light;
        }
    }

    &::-webkit-scrollbar-track {
        border-radius: 3px;
    }
}

@mixin itemsMargins($marginTop: null) {
    @if ($marginTop != null) {
        & > * {
            margin-bottom: 0;

            & + * {
                margin-top: $marginTop * 1px;
            }
        }
    }
}

@mixin itemsMarginsChange($marginTop: null) {
    @if ($marginTop != null) {
        & > * + * {
            margin-top: $marginTop * 1px;
        }
    }
}

@mixin imgBlock {
    display: block;
    width: 100%;
    height: auto;
}

@mixin bg64($src, $size: null) {
    background-image: url('data:image/svg+xml;base64,' + $src);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    /*@if ($size != null) {
        background-size: $size;
    } @else {
        background-size: contain;
    }*/
}

@mixin bg64_url($src) {
    background-image: url('data:image/svg+xml;base64,' + $src) !important;
}

@mixin bg_cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin bg_contain($src) {
    background-image: url($src);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
}

@mixin ratio($width, $height) {
    padding-bottom: math.div($height, $width) * 100%;
}

@mixin videoFrame {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
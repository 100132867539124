@import "_vars";
@import "mix";
@import "responsive";

body {
    font-family: $font_main;
    color: $color_black;
    min-width: $tel_xxs;
    @include ff(16, 400);
}

.locked {
    overflow: hidden !important;
}

.locked-y {
    overflow-y: hidden !important;
}

#wrapper {
    position: relative;
    //overflow: hidden;

    @include media_tablet_vert_bootstrap {
        overflow: hidden;
    }
}

b, strong, th, dt {
    font-weight: 500;
}

a {
    color: inherit;
    cursor: pointer;
    display: inline-block;
    outline: none !important;
    text-decoration: none;
    transition: all .15s ease-in-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::-moz-focus-inner {
        border: none;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: $color_primary_hover;
            transition: all 0.15s ease-in-out;
        }

        &:focus, &:active {
            color: $color_primary_active;
            transition: all 0.15s ease-in-out;
        }
    }

    p & {
        display: inline;
    }

    &[href^="tel"], &[href^="mailto"] {
        text-decoration: none;
        white-space: nowrap;
    }

    &.more-link {
        &:after {
            content: '\e916';
            margin-left: 12px;
        }

        &:hover {
            &:after {
                transform: translateX(2px);
            }
        }
    }

    &.back-link {
        &:before {
            content: '\e917';
            margin-right: 12px;
        }

        &:hover {
            &:before {
                transform: translateX(-2px);
            }
        }
    }

    &.more-link,
    &.back-link {
        font-weight: 500;
        text-decoration: none;

        &:before,
        &:after {
            display: inline-block;
            position: relative;
            transition: 0.15s ease-in-out;
            @include koko;
            @include ff(12);
        }

        &:hover {
            color: initial;

            &:before,
            &:after {
                transition: 0.15s ease-in-out;
            }
        }
    }

    &.self {
        text-decoration: none;
        border-bottom: 1px dashed;
    }
}

a.text-link {
    color: $color_primary;

    &:hover {
        color: $color_primary_hover;
    }
}

.sign-text {
    color: $color_grey_light;
    font-size: 80%;
    line-height: 80%;
}

img {
    max-width: 100%;
}

.size-container {
    max-width: 1146px;
    margin: 0 auto;
    position: relative;
    height: 100%;

    @include media_tablet_bootstrap {
        padding: 0 30px;
    }

    @include media_tablet_vert_bootstrap {
        padding: 0 24px;
    }

    @include media_tel_lg {
        padding: 0 15px;
    }

    @include media_tel_xs {
        padding: 0 10px;
    }
}

.outer-container {
    max-width: 1366px;
    margin: 0 auto;
    position: relative;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;

    &-grey {
        background-color: $color_default;
    }
}

#carousel-category-banner {
    margin-top: 15px;
}

main {
    position: relative;
    //overflow: hidden;
    padding-top: 40px;

    @include media_tablet_vert_bootstrap {
        overflow: hidden;
    }

    @include media_tel {
        padding: 20px 0;
    }

    @include media_tel_xs {
        padding: 15px 0;
    }

    &.main-white {
        background-color: $white;
    }

    .main__container {
        @include clearfix;
    }

    $leftWidth: 292px;
    $leftWidth_tablet: 244px;

    .main__left {
        float: left;
        padding-right: 24px;
        width: $leftWidth;

        @include media_tablet_bootstrap {
            width: $leftWidth_tablet;
        }

        @include media_tablet_vert_bootstrap {
            display: none;
        }

        &-container {
            position: sticky;
            top: 116px;
        }
    }

    .main__right {
        margin-left: $leftWidth;

        @include media_tablet_bootstrap {
            margin-left: $leftWidth_tablet;
        }

        @include media_tablet_vert_bootstrap {
            margin-left: 0;
        }
    }

    /*table {
        margin-bottom: 15px;
        max-width: 100%;
        width: 100%;

        @include media_tel {
            margin-bottom: 10px;
        }

        td {
            padding: 10px 15px;
            text-align: center;

            @include media_tel {
                padding: 10px;
            }

            & + td {
                border-left: 1px solid $color_border;
            }
        }

        thead {
            td {
                font-weight: 500;
                vertical-align: middle;
            }
        }

        tbody {
            tr:nth-child(odd) td {
                background-color: $brown4;
            }
        }
    }*/
}

h1, h2, h3, h4, h5 {
    color: inherit;
    padding: 0;
}

h2, h3, h4, h5 {
    font-family: inherit;

    * + & {
        padding-top: 15px;

        @include media_tel {
            padding-top: 10px;
        }
    }
}

h1, .h1-like {
    margin: 0 0 24px;
    @include ff(52, 500, 54);

    @include media_tablet_vert_bootstrap {
        @include ff(48);
    }

    @include media_tel {
        margin-bottom: 20px;
        @include ff(28, null, 26);
    }

    @include media_tel_xxs {
        @include ff(24, null, 22);
    }
}

h2 {
    margin: 0 0 24px;
    @include ff(36, 500);

    @include media_tablet_bootstrap {
        margin-bottom: 20px;
        @include ff(24);
    }

    @include media_tel {
        margin-bottom: 20px;
        @include ff(28);

        &.h1-like {
            @include ff(20);
        }
    }

    @include media_tel_xxs {
        @include ff(18);

        &.h1-like {
            @include ff(18);
        }
    }
}

* + h2 {
    padding-top: 24px;
}

h3 {
    margin: 0 0 24px;
    @include ff(24, 500);

    @include media_tel {
        margin-bottom: 20px;
        @include ff(20);
    }

    @include media_tel_xxs {
        @include ff(16);
    }
}

h4 {
    text-transform: uppercase;
    @include ff(18, 500);

    @include media_tel {
        @include ff(16);
    }
}

h5 {
    font-weight: 500;
}

p {
    margin-bottom: 16px;
}

.show-tel {
    display: none !important;

    @include media_tel {
        display: block !important;
    }
}

.hide-tel {
    @include media_tel {
        display: none !important;
    }
}
@import "__vars";
@import "_mix";
@import "_responsive";

.breadcrumbs {
    color: $color_grey_light;
    margin-bottom: 16px;
    @include ff(12);

    ul {
        flex-wrap: wrap;
        @include clearList;

        &, li {
            display: flex;
        }

        li {
            align-items: center;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    &__separator {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: $color_grey_light;
        margin: 0 6px;
    }

    &-center {
        ul {
            justify-content: center;
        }
    }

    &-dark {
        color: $font_color;

        .breadcrumbs__separator {
            background-color: $font_color;
        }
    }
}

main .breadcrumbs {
    @include media_tablet_vert_bootstrap {
        display: none;
    }

    &.breadcrumbs-always-show {
        display: block !important;
    }
}
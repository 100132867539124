@use "sass:math";
@import "_vars";
@import "mix";
@import "responsive";

.landing-tags, .category-tags {
    display: flex;
    flex-wrap: wrap;

    * + & {
        margin-top: 48px !important;

        @include media_tel {
            margin-top: 30px !important;
        }
    }

    &-category {
        margin: 24px 0 48px !important;

        @include media_tel {
            margin: 24px 0 36px !important;
        }
    }

    a {
        display: block;
        text-decoration: none !important;
        color: $font_color !important;
        background-color: $white;
        border: 1px solid $color_border;
        border-radius: 18px;
        padding: 6px 11px;
        margin: 0 8px 8px 0;

        @include media_tel {
            @include ff(12);
        }

        &:hover {
            background-color: $color_primary_hover;
            border-color: $color_primary_hover;
        }

        &:focus {
            background-color: $color_primary_active;
            border-color: $color_primary_active;
        }

        &.active {
            background-color: $color_primary_hover;
            border-color: $color_primary_hover;
        }
    }
}

.category-filtering {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 80;
    margin-bottom: 36px;

    @include media_tablet_vert_bootstrap {
        align-items: flex-end;
    }

    @include media_tel {
        flex-direction: column;
        align-items: stretch;
    }

    &__left {
        display: flex;
        align-items: center;

        @include media_tablet_vert_bootstrap {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include media_tel {
            //justify-content: space-between;
            flex-direction: row;
            align-items: center;
            margin-top: 12px;
        }
    }

    &__date {
        @include media_tel {
            display: none;
        }
    }

    &__count {
        color: $color_grey_light;
        margin-left: 32px;

        @include media_tablet_vert_bootstrap {
            margin: 24px 0 0;
        }

        @include media_tel {
            margin: 0;
        }
    }

    &__sort {
        display: flex;
        align-items: center;

        @include media_tablet_vert_bootstrap {
            //margin-top: 24px;
        }

        @include media_tel {
            //margin: 0;
            justify-content: space-between;
            width: 100%;
        }

        & > * + * {
            margin-left: 30px;

            @include media_tel {
                margin-left: 15px;

                .drop-down__container {
                    left: auto;
                    right: 0;
                }
            }
        }
    }

    &__filter {
        display: none;

        @include media_tablet_vert_bootstrap {
            display: block;
        }

        @include media_tel {
            order: 2;
        }

        .btn-open-filter {
            padding: 6px 12px !important;

            @include media_tel {
                padding: 6px !important;

                i {
                    font-size: 24px !important;
                    line-height: 24px !important;
                }

                span {
                    display: none !important;
                }
            }
        }
    }
}

.assortment {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    @include media_tel {
        margin: -5px !important;
    }

    &__item {
        padding: 10px;
        position: relative;
        @include flexSize(math.div(100%, 3));
        @include square;

        @include media_tel {
            flex-basis: 50%;
            max-width: 50%;
            width: 50%;
            padding: 5px !important;
        }

        &-container {
            &, & > a {
                position: relative;
                height: 100%;
            }

            &, & > a, .assortment__item-image {
                overflow: hidden;
                border-radius: 12px;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 14px;
            text-decoration: none !important;
            color: $white !important;
            @extend .preload-bg;

            @include media_tel {
                padding: 12px;
            }

            &:after {
                content: '';
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000',GradientType=0);
            }
        }

        &-count {
            background-color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
            line-height: 16px;
            padding: 1px 7px;
            border-radius: 9px;
            margin-bottom: 8px;

            @include media_tel {
                margin: 0 0 6px -2px;
                padding: 3px 7px;
            }
        }
    }
}

.catalog-block, .slider-block {
    margin-bottom: 48px;

    @include media_tel {
        margin-bottom: 30px;
    }
}

.slider-block {
    &__title {
        display: flex;
        align-items: flex-end;
        margin-bottom: 28px;
        padding-right: 80px;

        @include media_tel {
            padding-right: 0;
            margin-bottom: 16px;
            align-items: center;
        }

        a {
            text-decoration: none;
        }

        & > *, h1, h2, h3, h4, h5 {
            margin-bottom: 0 !important;
        }

        &-vert {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__go {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 24px !important;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-color: $white;
        color: $color_black !important;
        border: 1px solid $color_border;
        text-decoration: none;

        &:before {
            content: '\e921';
            @include koko;
            @include fi(12);
        }

        &:hover {
            background-color: $color_primary_hover;
        }

        &:active {
            background-color: $color_primary_active;
        }
    }

    &__intro {
        margin-top: 12px;

        [class^="koko"] {
            position: relative;
            top: 4px;
            @include fi(22);
        }
    }

    &__container {
        position: relative;
    }

    &__carousel {
        margin: 0;

        &-item {
            position: relative;
            overflow: hidden;
            border-radius: 12px;
            height: 156px;
            padding: 14px;
            text-decoration: none !important;
            color: $white !important;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @extend .preload-bg;

            @include media_tablet_bootstrap {
                height: 130px;
            }

            @include media_tel {
                padding: 12px;
                height: 102px;
            }

            @include media_tel_lg {
                height: 96px;
            }

            @include media_tel_xs {
                height: 82px;
            }
        }

        .owl-stage {
            display: flex;
        }

        .owl-nav {
            right: 0;
            bottom: 100%;
            top: auto;
            display: flex;
            align-items: center;
            position: absolute;
            transform: translateY(-28px);

            .owl-next, .owl-prev {
                position: relative;
                margin: 0;
                top: auto;
                left: auto;
                right: auto;
                transform: none;
            }

            .owl-next {
                margin-left: 8px;
            }
        }
    }

    &__rows {
        display: flex;
        flex-wrap: wrap;
        margin: -10px;

        @include media_tel {
            display: block;
            margin: 0;
        }

        .sets-carousel__slide {
            padding: 10px;
            @include flexSize(50%);

            @include media_tel {
                padding: 0;
                @include flexReset;

                & + * {
                    margin-top: 20px;
                }
            }
        }
    }
}

.assortment__item, .slider-block__carousel-item {
    &-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &-desc {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 5;
    }

    &-title {
        @include ff(24, 500);

        @include media_tel {
            @include ff(16);
        }
    }
}

.category-more {
    margin-top: 28px;

    @include media_tel {
        margin-top: 16px;
    }

    .products-container & {
        padding: 0 10px;
        @include flexSize(100%);

        @include media_tel {
            padding: 0 5px;
        }
    }
}
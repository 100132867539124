@use "sass:math";
@import "_vars";
@import "mix";
@import "responsive";

.events-links-slider {
    margin: 0;
    position: relative;
    height: 20px;
    overflow: visible;
    opacity: 0;
    white-space: nowrap;

    &.owl-loaded {
        opacity: 1 !important;
        transition: opacity 0.3s ease;
    }

    .owl-stage-outer {
        padding: 0 24px;
        overflow: hidden !important;

        &, .owl-stage, .owl-item {
            height: 100%;
            display: flex;
        }

        .owl-item {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            width: 26px;
            z-index: 10;
        }

        &:before {
            left: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
        }

        &:after {
            right: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 50%, rgba(255, 255, 255, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
        }

        @include media_tel_sm {
            padding-left: 16px;
            padding-right: 16px;

            &:before, &:after {
                width: 18px;
            }
        }
    }

    .owl-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;

        button {
            &.owl-prev {
                left: 0;
            }

            &.owl-next {
                right: 0;
            }

            &.disabled {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .slick-arrow {
        width: 32px;
        height: 32px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $color_border;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        z-index: 100;
        visibility: hidden;

        &:before {
            color: $font_color;
            font-size: 12px !important;
            line-height: 12px;
            opacity: 1;
            @include koko;
        }

        &.slick-prev {
            left: 0 !important;

            &:before {
                content: '\e917' !important;
                margin-left: -1px;
            }
        }

        &.slick-next {
            right: 0 !important;

            &:before {
                content: '\e916' !important;
                margin-right: -1px;
            }
        }
    }

    &__icon {
        margin-right: 4px;
        @include fi(14);

        & > * {
            display: block;
        }
    }

    &__text {
        transition: all 0.15s ease-in-out;
    }

    &__item {
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;

        &.events-links-slider__item-festival,
        &.events-links-slider__item-easter {
            .events-links-slider__text {
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &.events-links-slider__item-festival {
            .events-links-slider__icon {
                color: $color_festival_from;
            }

            .events-links-slider__text {
                background-color: $color_festival_from;
                background-image: linear-gradient(to right, $color_festival_from, $color_festival_to);
            }
        }

        &.events-links-slider__item-valentines-day {
            color: $color_festival_from;
        }

        &.events-links-slider__item-easter {
            .events-links-slider__text {
                background-color: $easter1;
                background-image: linear-gradient(to right, $easter1, $easter2, $easter3);
            }
        }

        &.events-links-slider__item-newyear {
            display: flex;
            align-items: center;

            &:before {
                content: '\e90b';
                margin-right: 5px;
                line-height: 16px;
                @include koko;
            }
        }

        &.events-links-slider__item-newyear,
        &.events-links-slider__item-green {
            color: $color_newyear;
        }

        &:hover, &:active {
            .events-links-slider__text {
                transition: all 0.15s ease-in-out;
                background-color: transparent;
                background-image: none;
                -webkit-text-fill-color: $color_primary_hover;
            }
        }

        &:hover {
            .events-links-slider__text {
                color: $color_primary_hover !important;
                transition: all 0.15s ease-in-out;
            }
        }

        &:active {
            .events-links-slider__text {
                color: $color_primary_active !important;
                transition: all 0.15s ease-in-out;
            }
        }
    }

    &-arrows {
        display: flex;
        align-items: center;
        height: 32px;

        .slick-arrow {
            visibility: visible;

            &.slick-disabled {
                visibility: hidden;
            }

            @include media_tablet_vert_bootstrap {
                display: none !important;
            }
        }
    }

    &.landing-tags {
        height: auto;
        overflow: hidden;
        opacity: 1;

        &.owl-carousel {
            opacity: 0;

            a {
                margin: 0;
            }
        }

        a.active {
            background-color: $color_primary;
            border-color: $color_primary;
        }
    }
}
@use "sass:math";
@import "inc/__vars";
@import "inc/_mix";
@import "inc/_responsive";
@import "inc/fonts";
@import "inc/basis";
@import "inc/input";
@import "inc/btn";
@import "inc/header";
@import "inc/footer";
@import "inc/carousel";
@import "inc/events-links-slider";
@import "inc/breadcrumbs";
@import "inc/preloaders";
@import "inc/category";
@import "inc/videos";

.header-block {
    .header-logo {
        display: flex !important;

        img {
            width: auto;

            &.header-logo-ed {
                width: 188px;

                @include media_tablet_vert_bootstrap {
                    width: 146px;
                }

                @include media_tel {
                    width: 118px;
                }
            }

            &.header-logo-blog {
                width: 92px;

                @include media_tablet_vert_bootstrap {
                    width: 74px;
                }

                @include media_tel {
                    margin-left: 2px;
                    width: 50px;
                }
            }
        }
    }

    &__top {
        background-color: $white;
        padding-bottom: 20px;

        @include media_tel {
            padding: 12px 0;
        }

        &-logo {
            margin-right: 40px;
            @include flexSizeChange(284px);

            @include media_tablet_vert_bootstrap {
                margin-right: 34px;
                @include flexSizeChange(222px);
            }

            @include media_tel {
                margin: 0;
                @include flexReset;
            }
        }

        &-menu {
            @include media_tel {
                display: none;
            }
        }
    }

    &__bot {
        @include media_tel {
            padding-bottom: 16px;
        }

        @include media_tel_xs {
            padding-bottom: 10px;
        }
    }

    &__top,
    &__bot {
        display: block !important;
    }

    .header-buttons {
        &-desktop {
            @include media_tel {
                display: none;
            }
        }

        &-mobile {
            display: none;

            @include media_tel {
                display: flex;
            }

            & > * + * {
                margin-left: 6px;
            }

            .btn {
                width: 40px;

                i {
                    font-size: 24px;
                }

                &.btn-open-menu {
                    &.active {
                        i {
                            &:before {
                                content: '\e935';
                            }
                        }
                    }
                }
            }
        }
    }

    .events-links-slider {
        .owl-nav {
            button {
                &.owl-prev {
                    left: -14px;

                    @include media_tel_lg {
                        left: -5px;
                    }
                }

                &.owl-next {
                    right: -14px;

                    @include media_tel_lg {
                        right: -5px;
                    }
                }
            }
        }
    }

    &__mobile {
        display: none;
        z-index: 100;
        position: fixed;
        left: 0;
        top: 64px;
        width: 100%;
        background-color: $white;
        border-radius: 0 0 16px 16px;
        padding-bottom: 16px;
        box-shadow: 0 10px 20px rgba(92, 89, 89, 0.04);

        &-menu {
            ul {
                @include clearList;

                li + li {
                    margin-top: 12px;
                }
            }
        }
    }

    &__back {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(21, 27, 26, .56);
        z-index: 0;
    }

    &__mobile,
    &__back {
        &.active {
            display: block;
        }

        @include media_more_than_tel {
            display: none !important;
        }
    }
}

#wrapper {
    padding: 148px 0 0;

    @include media_tel {
        padding-top: 112px;
    }

    @include media_tel_xs {
        padding-top: 106px;
    }
}

main {
    padding-top: 20px;
}

.breadcrumbs {
    margin-bottom: 36px;
}

.blog {
    &-categories-list {
        margin-bottom: 18px;

        @include media_tel {
            margin-bottom: 8px;
        }
    }

    &-news {
        display: flex;
        flex-wrap: wrap;
        margin: -20px -10px;

        @include media_tel {
            display: block;
            margin: 0;
        }

        &__item {
            padding: 20px 10px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            @include flexSize(math.div(100%, 3));

            @include media_tablet_vert_bootstrap {
                @include flexSizeChange(50%);
            }

            @include media_tel {
                padding: 0;
                @include flexReset;

                & + * {
                    margin-top: 24px;
                }
            }
        }

        &__image {
            position: relative;

            & > a {
                &, img {
                    display: block;
                }

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 16px;
                }
            }

            .blog__tags {
                position: absolute;
                left: 12px;
                top: 12px;
            }
        }

        &__desc {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 18px;
            min-height: 144px;

            @include media_tablet_bootstrap {
                margin-top: 12px;
                min-height: 134px;
            }

            @include media_tel {
                min-height: 0;
            }
        }

        &__title {
            @include ff(24, 500);
            @include inheritLink;

            @include media_tablet_bootstrap {
                @include ff(20);
            }
        }

        &__bot {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;

            .blog__stat {
                color: $color_grey_light;
            }
        }
    }

    &__tags {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include inheritLink;

        a {
            display: block;
            background-color: $white;
            border-radius: 4px;
            padding: 3px 8px;
            text-decoration: none;
            @include ff(12, 500);

            & + * {
                margin-top: 5px;
            }
        }
    }

    &__stat {
        display: flex;
        align-items: center;
    }

    &__date {
        margin-right: 16px;
    }

    &__views {
        display: flex;
        align-items: center;

        i {
            margin-right: 8px;
            @include fi(14);
        }
    }

    &__more {
        .more-link {
            &:after {
                margin-left: 4px;
            }
        }
    }

    &__author {
        display: flex;
        align-items: center;

        &-image {
            @include flexSize(56px);

            img {
                border-radius: 50%;
                @include imgBlock;
            }
        }

        &-desc {
            margin-left: 16px;
        }

        &-name {
            font-weight: 500;
        }

        &-about {
            margin-top: 2px;
            color: $color_grey_light;
        }
    }

    &__likes {
        display: flex;

        .btn {
            color: $color_grey_light;

            &.active {
                color: $font_color !important;
            }

            & + * {
                margin-left: 12px;
            }
        }
    }

    &__social {
        margin-top: 32px;
        display: flex;
        color: $color_grey_light;
        @include inheritLink;

        a {

            text-decoration: none;

            & + * {
                margin-left: 16px;
            }
        }
    }

    &-banner {
        margin-bottom: 80px;
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        height: 430px;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: auto 100%;

        @include media_tablet_bootstrap {
            height: 356px;
        }

        @include media_tablet_vert_bootstrap {
            height: 344px;
        }

        @include media_tel {
            margin-bottom: 36px;
            height: auto;
            padding-top: 180px;
            background-size: 100% auto;
            background-position: left top;
        }

        &__desc {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 30px 60px;

            @include media_tablet_bootstrap {
                padding: 30px 56px;
            }

            @include media_tablet_vert_bootstrap {
                padding: 30px 36px;
            }

            @include media_tel {
                position: relative;
                top: auto;
                right: auto;
                width: 100%;
                height: auto;
                padding: 24px 24px 30px;
            }

            @include media_tel_lg {
                padding: 24px 15px;
            }

            @include media_tel_xs {
                padding: 20px 10px 15px;
            }

            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 150%;
                background-color: $color_primary;
                left: 0;
                top: 0;
                transform: skewX(-7.68deg);

                @include media_tel {
                    transform: skewY(4.06deg);
                    width: 100%;
                    height: 150%;
                }
            }
        }

        &__container {
            position: relative;
        }

        &__title {
            margin-top: 8px;
            @include ff(52, 500, 56);

            @include media_tablet_bootstrap {
                @include ff(36);
            }

            @include media_tel {
                @include ff(24);
            }
        }

        .blog__stat,
        .blog__more {
            margin-top: 16px;
        }

        @include media_tel {
            .blog__stat {
                margin-top: 12px;
            }

            .blog__more {
                margin-top: 8px;
            }
        }
    }

    &-breadcrumbs {
        @include media_tel {
            display: none;
        }
    }

    &-back {
        display: none;
        margin-bottom: 24px;

        @include media_tel {
            display: block;
        }
    }

    &-inner {
        &__container {
            max-width: 952px;

            @include media_tablet_bootstrap {
                max-width: 800px;
            }

            @include media_tablet_vert_bootstrap {
                max-width: 536px;
            }

            @include media_tel {
                max-width: none;
            }

            .blog__stat {
                color: $color_grey_light;
                margin-bottom: 14px;
            }
        }

        h1 {
            margin-bottom: 22px;
            @include ff(36);

            @include media_tablet_vert_bootstrap {
                @include ff(24);
            }
        }

        h2 {
            margin-bottom: 16px;
            @include ff(24);

            @include media_tablet_vert_bootstrap {
                @include ff(20);
            }
        }

        &__banner {
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            margin-bottom: 52px;

            @include media_tel {
                margin-bottom: 40px;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
            }

            .blog__tags {
                position: absolute;
                left: 20px;
                top: 20px;

                @include media_tel {
                    left: 12px;
                    top: 12px;
                }
            }
        }

        &__description {
            ul {
                padding: 0;
                margin: 0 0 12px 0;

                li {
                    margin: 0;
                    padding: 0 0 0 36px;
                    position: relative;

                    @include media_tel {
                        padding-left: 16px;
                    }

                    & + li {
                        margin-top: 12px;
                    }

                    &::marker {
                        content: '';
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $color_primary;
                        left: 14px;
                        top: 8px;

                        @include media_tel {
                            left: 0;
                        }
                    }
                }
            }

            a {
                color: $color_primary;

                &:hover {
                    color: $color_primary_hover;
                }
            }
        }

        &__block {
            margin: 48px 0;

            @include media_tel {
                margin: 30px 0;
            }
        }

        &__slider {
            &-container {
                border-radius: 16px;
                margin: 0;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                .owl-nav {
                    .owl-prev {
                        left: 12px;
                    }

                    .owl-next {
                        right: 12px;
                    }

                    @include media_more_than_tel {
                        .owl-prev,
                        .owl-next {
                            width: 50px;
                            height: 50px;

                            &:before {
                                @include fi(18);
                            }
                        }

                        .owl-prev {
                            left: 26px;

                            &:before {
                                margin-left: -2px;
                            }
                        }

                        .owl-next {
                            right: 26px;

                            &:before {
                                margin-right: -2px;
                            }
                        }
                    }
                }
            }

            &-title {
                margin-top: 12px;
                color: $color_grey_light;
                @include ff(12);
            }
        }

        &__video {
            height: 504px;
            position: relative;

            @include media_tablet_vert_bootstrap {
                height: 316px;
            }

            @include media_tel {
                height: 212px;
            }

            @include media_tel_xs {
                height: 160px;
            }

            .video-placeholder {
                &__dumb {
                    &:before {
                        width: 90px;
                        height: 146px;
                        background-color: transparent;
                        border-radius: 0;
                        margin-top: -38px;
                        @include bg64('PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iMTQ2IiB2aWV3Qm94PSIwIDAgOTAgMTQ2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNiAxNDZMMCAwTDkwIDgzTDYgMTQ2WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');

                        @include media_tel {
                            width: 34px;
                            height: 55px;
                            margin-top: -18px;
                        }
                    }

                    &:after {
                        content: 'Смотреть видео';
                        background: none;
                        width: auto;
                        height: auto;
                        color: $white;
                        margin: 90px 0 0;
                        @include ff(24, 500);

                        @include media_tel {
                            margin-top: 30px;
                            @include ff(16);
                        }
                    }
                }
            }
        }

        &__bottom {
            padding-top: 22px;

            .blog__likes {
                margin-top: 26px;
            }

            & + * {
                margin-top: 56px;
                border-top: 2px solid $color_background;
                padding-top: 48px;
            }
        }
    }

    &-cooking {
        background-color: $color_background;
        border-radius: 16px;
        padding: 34px;
        position: relative;
        overflow: hidden;

        @include media_tel {
            margin-left: -24px;
            margin-right: -24px;
            padding: 24px;
        }

        @include media_tel_lg {
            margin-left: -15px;
            margin-right: -15px;
            padding: 24px 15px;
        }

        @include media_tel_xs {
            margin-left: -10px;
            margin-right: -10px;
            padding: 20px 10px;
        }

        &__item {
            position: relative;
            overflow: hidden;
            border-radius: 16px;
        }

        &__image {
            a, img {
                display: block;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__desc {
            background-color: $white;
            border-radius: 0 0 16px 16px;
            border: 1px solid $color_border;
            padding: 12px;
        }

        &__title {
            @include ff(12, 500);
            @include inheritLink;
        }

        &__from {
            margin-top: 4px;
            color: $color_grey_light;
            @include ff(10);
            @include inheritLink;
        }

        &__button {
            margin-top: 14px;

            .btn {
                display: block;
            }
        }

        .slider-block {
            &__carousel {
                .owl-nav {
                    transform: translateY(-26px);

                    @include media_tablet_vert_bootstrap {
                        transform: translateY(-24px);
                    }

                    @include media_tel {
                        transform: translateY(-12px);
                    }
                }
            }
        }
    }

    &-carousel-block {
        margin-bottom: 0;

        & + * {
            margin-top: 80px;

            @include media_tel {
                margin-top: 48px;
            }
        }

        .slider-block__title {
            @include media_more_than_tel {
                margin-bottom: 36px;
            }

            @include media_tel {
                margin-bottom: 24px;
            }

            h2 {
                @include media_more_than_tel {
                    @include ff(36);
                }
            }
        }

        .owl-carousel {
            .owl-nav {
                @include media_more_than_tel {
                    transform: translateY(-38px);
                }

                @include media_tel {
                    transform: translateY(-20px);
                }
            }
        }
    }

    &-products {
        @include media_more_than_tel {
            .blog-cooking {
                &__desc {
                    padding: 16px;
                }

                &__title {
                    @include ff(16);
                }

                &__from {
                    @include ff(12);
                }

                &__button {
                    margin-top: 30px;
                }
            }
        }
    }

    &-articles {
        .blog-news__item {
            padding: 0;
            @include flexReset;
        }
    }

    &-statement {
        background-color: $color_background;
        border-radius: 16px;
        padding: 52px;
        position: relative;
        overflow: hidden;

        @include media_tel {
            padding: 24px;
        }

        @include media_tel_lg {
            padding: 15px;
        }

        &__text {
            @include ff(24, 500);
            @include itemsMargins(16);

            @include media_tel {
                @include ff(20);
            }
        }

        .blog__author {
            margin: 26px 0 0 8px;

            @include media_tel {
                margin: 24px 0 0 6px;
            }

            @include media_tel_lg {
                margin-top: 16px;
            }
        }
    }

    &-gallery {
        @include media_tel {
            display: none;
        }

        &__container {
            display: flex;
            flex-wrap: wrap;
            margin: -24px -10px;
        }

        &__item {
            padding: 24px 10px;

            img {
                border-radius: 16px;
                @include imgBlock;
            }

            &-col-33 {
                @include flexSize(math.div(100%, 3));
            }

            &-col-50 {
                @include flexSize(50%);
            }
        }
    }
}
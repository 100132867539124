@use "sass:math";
@import "_vars";
@import "mix";
@import "responsive";

.video-placeholder {
    background-color: $color_default;
    border-radius: 16px;
    position: relative;
    overflow: hidden;

    &, &__dumb {
        width: 100%;
        height: 100%;
    }

    &__dumb {
        cursor: pointer;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &:before, &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: transform 0.15s ease-out;
        }

        &:before {
            width: 80px;
            height: 80px;
            background-color: $color_primary;
            border-radius: 50%;

            @include media_tel {
                width: 56px;
                height: 56px;
            }

            @include media_tel_xs {
                width: 44px;
                height: 44px;
            }
        }

        &:after {
            width: 31px;
            height: 48px;
            margin-left: 3px;
            @include bg64('PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCAzMSA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuMDY2NjcgNDhMMCAwTDMxIDI3LjI4NzdMMi4wNjY2NyA0OFoiIGZpbGw9IiMxNTFCMUEiLz4KPC9zdmc+Cg==');

            @include media_tel {
                width: 22px;
                height: 34px;
            }

            @include media_tel_xs {
                width: 17px;
                height: 26px;
            }
        }

        &:hover {
            &:before, &:after {
                transform: translate(-50%, -50%) scale(1.2);
                transition: transform 0.15s ease-in;
            }
        }
    }

    &__container {
        height: 100%;
        position: relative;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__close {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);

        &:before {
            content: 'Закрыть';
        }
    }
}
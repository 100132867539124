@use "sass:math";
@import "_vars";
@import "mix";
@import "responsive";

.form-box {
    position: relative;

    & + * {
        margin-top: 16px;
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="url"],
    input[type="search"],
    textarea {
        appearance: none;
        background-color: $color_default;
        border: 1px solid $color_default;
        border-radius: 12px;
        color: $font_color;
        font-family: $font_main;
        height: 56px;
        display: block;
        width: 100%;
        transition: all 0.15s ease-in-out;
        @include ff($font-size, 400, $line-height);

        &:hover {
            background-color: $color_input_hover;
            border-color: $color_input_hover;
            transition: all 0.15s ease-in-out;
        }

        &:focus {
            background-color: $white;
            border-color: $color_primary_active;
            transition: all 0.15s ease-in-out;
        }

        &[disabled] {
            color: $color_grey_light;
            transition: all 0.15s ease-in-out;
        }
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="url"],
    input[type="search"] {
        padding: 16px 20px;
    }

    textarea {
        padding: 10px 20px;
        height: 160px;
        resize: none;

        @include media_tel_lg {
            height: 148px;
            padding: 10px 14px;
        }
    }

    &.has-error {
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="url"],
        input[type="search"],
        textarea {
            border-color: $color_red;
            color: $color_red;
            background-color: $white;
        }
    }
}

.form-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    @include itemsMargins(12);

    .btn {
        display: block;
    }
}

.form-sign {
    margin-top: 20px;
    color: $color_grey_light;
    text-align: center;
    @include ff(12);
}

.form-error {
    color: $red2;
    text-align: center;
    @include ff(12);

    * + &,
    & + * {
        margin-top: 12px;
    }

    &.active {
        display: block;
    }
}

.box-placeholder {
    position: relative;

    .placeholder {
        @include ff($font_size, 500);
        color: $placeholder;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        transition: all 0.15s ease;
    }

    textarea ~ .placeholder {
        top: 14px;
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="url"],
    input[type="search"],
    textarea,
    select {
        &:focus, &.input-filled {
            padding-top: 18px;
            padding-bottom: 8px;

            & ~ .placeholder {
                @include ff(12);
                top: 10px;
                transition: all 0.15s ease;
            }
        }
    }

    &.has-error {
        .placeholder {
            color: $red2;
        }
    }
}

.radio-box-labels {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    @include media_tel {
        flex-flow: column nowrap;
        align-items: stretch;
        margin: 0;
    }

    label {
        display: block;
        cursor: pointer;
        margin: 0;
        position: relative;
        height: 100%;
    }

    &__item {
        padding: 10px;
        @include flexSize(50%);

        @include media_tel {
            padding: 0;
            @include flexReset;

            & + * {
                margin-top: 10px;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        padding: 14px 48px 14px 20px;
        border: 1px solid $color_border;
        border-radius: 5px;
        position: relative;
        height: 100%;

        @include media_more_than_tel {
            padding: 14px 20px;
        }

        @include media_more_than_tel {
            flex-direction: column;
            align-items: flex-start;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
        }

        &:before {
            width: 18px;
            height: 18px;
            border: 1px solid $color_border;
            right: 17px;
            top: 17px;
        }

        &:after {
            display: none;
            width: 8px;
            height: 8px;
            background-color: $color_primary_active;
            right: 21px;
            top: 21px;
        }
    }

    input {
        display: none;

        &:checked + .radio-box-labels__container {
            border-color: $color_primary_active;
            border-width: 2px;
            padding: 13px 47px 13px 19px;

            @include media_more_than_tel {
                padding: 13px 19px;
            }

            &:before {
                border-color: $color_primary_active;
                border-width: 2px;
                right: 16px;
                top: 16px;
            }

            &:after {
                display: block;
            }
        }

        &[disabled] + .radio-box-labels__container {
            .radio-box-labels__image img {
                filter: grayscale(1);
            }

            .radio-box-labels__title, .radio-box-labels__text {
                color: $color_grey_light;
            }

            &:before, &:after {
                display: none;
            }
        }
    }

    &__image {
        @include flexSize(65px);

        @include media_more_than_tel {
            @include flexReset;
        }

        img {
            display: block;
            height: 38px;
            width: auto;
            max-width: 100%;
        }
    }

    &__desc {
        flex-grow: 1;
        margin-top: 6px;

        @include media_more_than_tel {
            margin-top: 18px;
        }
    }

    &__title {
        @include ff(14, 500);
    }

    &__text {
        color: $color_grey_light;
        margin-top: 6px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include ff(12);

        * {
            margin-bottom: 0;

            & + * {
                margin-top: 5px;
            }
        }

        .product-warning {
            margin-top: 6px;
        }
    }

    &-list {
        display: block;
        margin: 0;

        .radio-box-labels__item {
            padding: 0;
            @include flexReset;

            & + * {
                margin-top: 16px;
            }
        }

        .radio-box-labels__container {
            flex-direction: row;
            align-items: center;
            border-width: 2px;
            border-radius: 12px;
            padding: 14px 48px 14px 24px !important;
            min-height: 68px;

            &:before {
                width: 20px;
                height: 20px;
                right: 14px !important;
                top: 14px !important;
                border-width: 2px;
                align-items: center;
                justify-content: center;
                display: flex;
                @include koko;
                @include fi(10);
            }

            &:after {
                display: none !important;
            }
        }

        input {
            &:checked + .radio-box-labels__container {
                &:before {
                    content: '\e90f';
                    background-color: $color_primary_active;
                    padding: 1px 1px 0 0;
                }
            }
        }

        .radio-box-labels__image {
            margin-right: 26px;
            @include flexSize(40px);

            @include media_tel {
                margin-right: 20px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .radio-box-labels__desc {
            margin: 0;
            flex-grow: 1;
        }

        .radio-box-labels__title {
            @include ff(16);
        }

        .radio-box-labels__text {
            margin: 0;
            @include ff(12);
        }
    }
}

.product-warning {
    background-color: $yellow4;
    color: $brown6;
    font-size: 12px;
    line-height: 14px;
    padding: 4px;
    border-radius: 11px;
    display: flex;
    align-items: center;

    &:before {
        content: '\e912';
        margin-right: 4px;
        @include ico;
        @include fi(14);
    }
}

.express-timer {
    color: $color_black;

    span {
        color: $color_primary_active;
    }
}

.select-dropdown {
    background-color: $color_default;
    border-radius: 5px;
    position: relative;

    &__display {
        position: relative;
        height: 48px;
        padding: 8px 40px 8px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        cursor: pointer;
        @include ff(14);

        &:after {
            content: '\e90a';
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            transition: all 0.15s ease-in-out;
            @include ico;
            @include fi(7);
        }

        &.active {
            justify-content: space-between;

            .select-dropdown__placeholder {
                transition: all 0.15s ease-out;
                @include ff(10);
            }
        }
    }

    &__placeholder {
        color: $placeholder;
        transition: all 0.15s ease-in;
    }

    &__value {
        display: flex;
        align-items: center;

        .product-warning {
            margin-left: 15px;
        }

        &-text {

        }

        &-icon {
            display: none;
            margin-left: 6px;
        }

        &.new-year,
        &.christmas {
            &:after {
                content: '\e90b';
                display: inline-block;
                margin-left: 4px;
                position: relative;
                top: 1px;
                @include koko;
            }
        }

        &.new-year {
            .select-dropdown__value-text {
                color: $color_newyear;
            }
        }

        &.christmas {
            .select-dropdown__value-text {
                color: $red2;
            }
        }

        &.easter {
            .select-dropdown__value-text {
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                background-color: $easter1;
                background-image: linear-gradient(to right, $easter1, $easter2, $easter3);
            }

            .select-dropdown__value-icon {
                display: block;
            }
        }
    }

    &__list {
        display: none;
        border-top: 1px solid $placeholder;
        padding: 7px 0;

        &.active {
            display: block;
        }

        &-text {

        }

        &-icon {
            margin-left: 6px;
        }

        &-item {
            cursor: pointer;
            padding: 7px 38px 7px 16px;
            position: relative;
            display: flex;
            align-items: center;

            &:after {
                margin-left: 15px;
                display: none;
                content: '\e900';
                color: $color_primary_active;
                @include ico;
                @include fi(9);
            }

            &.active {
                &:after {
                    display: block;
                }
            }

            .product-warning {
                margin-left: 15px;
            }

            &[data-event] {
                span {
                    &:after {
                        display: inline-block;
                        margin-left: 6px;
                        position: relative;
                        top: 1px;
                        @include koko;
                    }
                }
            }

            &[data-event="new-year"],
            &[data-event="christmas"] {
                .select-dropdown__list-text {
                    &:after {
                        content: '\e90b';
                    }
                }
            }

            &[data-event="easter"] {
                .select-dropdown__list-text {
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    background-color: $easter1;
                    background-image: linear-gradient(to right, $easter1, $easter2, $easter3);
                }
            }

            &[data-event="new-year"] {
                .select-dropdown__list-text {
                    color: $color_newyear;
                }
            }

            &[data-event="christmas"] {
                .select-dropdown__list-text {
                    color: $red2;
                }
            }
        }
    }

    &.active {
        .select-dropdown__display {
            &:after {
                transform: translateY(-50%) rotate(180deg);
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

.input-live {
    background-color: $color_default;
    border-radius: 5px;
    position: relative;

    &:before {
        content: '\e932';
        color: $color_grey_light;
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 0;
        @include ico;
        @include fi(14);
    }

    &__close {
        display: none;
        color: $color_black;
        position: absolute;
        right: 18px;
        top: 8px;
        text-decoration: none;
        z-index: 20;
        padding: 10px;
        @include ico;
        @include fi(10);

        &:hover {
            color: $color_primary_hover;
        }

        &:before {
            content: '\e909';
            display: block;
        }
    }

    &__input {
        position: absolute;
    }

    &__input {
        background: transparent;
        color: $font_color;
        border: none;
        display: block;
        width: 100%;
        padding: 14px 38px 14px 38px;
        position: relative;
        z-index: 10;
        @include ff(14);

        &::placeholder {
            color: $color_grey_light;
        }
    }

    textarea {
        //height: 46px;
        overflow: hidden;
        overflow-wrap: break-word;
        resize: none;
    }

    &__list-container {
        display: none;
        background-color: $white;
        border-radius: 0 0 5px 5px;
        position: absolute;
        overflow: hidden;
        left: 0;
        top: 100%;
        padding: 10px 16px;
        width: 100%;
    }

    &__list {
        position: relative;
        overflow-y: auto;
        max-height: 340px;
        @include scroll;

        @include media_tel_xs {
            max-height: 260px;
        }

        &-item {
            padding: 10px 22px;
            position: relative;
            cursor: pointer;

            &:before, &:after {
                display: none;
                position: absolute;
                @include ico;
            }

            &:after {
                content: '\e900';
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                @include fi(10);
            }

            &:hover {
                color: $color_primary_hover;
            }

            &.active {
                color: $color_primary_active;
            }

            &:hover, &.active {
                &:before {
                    color: inherit;
                }

                &:after {
                    display: block;
                }
            }

            span {
                display: block;
                @include ff(16);
            }
        }
    }

    &.open {
        .input-live__list-container {
            display: block;
        }
    }

    &.active {
        &:before {
            color: $color_grey_dark;
        }

        .input-live__close {
            display: block;
        }
    }

    &-address {
        .input-live__list-item:before {
            display: block;
            content: '\e935';
            color: $color_grey_light;
            top: 16px;
            left: 1px;
            @include fi(12);
        }
    }
}

$checkSize: 20px;
$pointSize: 10px;

.label-checkbox {
    input[type="checkbox"], input[type="radio"] {
        & + span {
            display: inline-block;
            padding-left: $checkSize + 12;
            position: relative;
            text-align: left;

            &:before, &:after {
                content: '';
                position: absolute;
            }

            &:before {
                height: $checkSize;
                width: $checkSize;
                left: 0;
                top: 0;
            }

            &:after {
                visibility: hidden;
                opacity: 0;
                transform: scale(0);
                transition: all 0.15s ease-out;
            }
        }

        &:checked + span:after {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
            transition: all 0.15s ease-in;
        }

        &[type="checkbox"] {
            & + span {
                &:before {
                    background-color: $color_border;
                    border-radius: 3px;
                }

                &:after {
                    content: '\e90f';
                    color: $color_black;
                    left: 0;
                    top: 0;
                    height: $checkSize;
                    width: $checkSize;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include koko;
                    @include fi(10);
                }
            }

            &:checked + span {
                &:before {
                    background-color: $color_primary;
                }
            }
        }

        &[type="radio"] {
            & + span {
                &:before, &:after {
                    border-radius: 50%;
                }

                &:before {
                    background-color: $white;
                    border: 2px solid $color_border;
                }

                &:after {
                    background-color: $color_primary;
                    height: $pointSize;
                    width: $pointSize;
                    left: math.div($checkSize - $pointSize, 2);
                    top: math.div($checkSize - $pointSize, 2);
                    display: block;
                }
            }
        }

        &.has-error + span {
            &:before {
                background-color: $color_red;
            }
        }
    }
}

.label-tag, .label-checkbox {
    display: block;
    margin: 0;
    //font-size: initial;
    //line-height: initial;

    input[type="checkbox"], input[type="radio"] {
        display: none;
    }
}

.label-tag {
    span {
        border: 1px solid $color_border;
        border-radius: 18px;
        background-color: $white;
        display: block;
        padding: 7px 10px;
        transition: all 0.15s ease-in-out;
    }

    input:checked + span {
        border-color: $color_primary;
        background-color: $color_primary;
        transition: all 0.15s ease-in-out;
    }
}

.checkboxes {
    @include clearList;

    & > li + * {
        margin-top: 14px;
    }

    &-flex {
        display: flex;

        & > * {
            margin: 0 12px 12px 0;
        }
    }
}

.range-filter {
    .range-filter__values {
        display: flex;
        border: 1px solid $border_color;
        border-radius: 5px;
        background-color: $white;
    }

    .range-filter__input {
        flex: 1 0 0;
        position: relative;

        & + * {
            border-left: 1px solid $color_border;
        }

        input {
            display: block;
            position: relative;
            width: 100%;
            padding: 7px 31px 7px 11px;
            color: $font_color;
            border: none;
            background: transparent;
            @include ff(16);
        }

        &:after {
            content: '\EA01';
            color: $placeholder;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 11px;
            margin-top: 1px;
            @extend .CurrencyIcon;
        }
    }

    &__slider {
        margin-top: 26px;

        &-container {
            background-color: $color_border;
            border-radius: 4px;
            height: 4px;
            border: none !important;

            .ui-slider-range {
                background-color: $color_primary;
            }

            .ui-slider-handle {
                background-color: $color_primary;
                border-radius: 50%;
                border: none;
                height: 16px;
                width: 16px;
                top: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
            }
        }
    }

    &__labels {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: $color_grey_light;
    }
}
@import "_vars";
@import "mix";
@import "responsive";

.btn {
    text-decoration: none !important;
    transition: all .2s ease;

    &:hover {
        transition: all .2s ease;
    }

    &.btn-cart {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color_black ;
        background-color: $color_primary;
        border-radius: 50%;
        @include fi(28);

        @include media_tel {
            width: 36px;
            height: 36px;
            @include fi(24);
        }

        /*@include media_tel_xs {
            width: 30px;
            height: 30px;
            @include fi(12);
        }*/

        &:before {
            content: '\e907';
            @include koko;
        }

        &:hover {
            background-color: $color_primary_hover;
        }

        &:active {
            background-color: $color_primary_active;
        }

        &-notice {
            background-color: $color_black;
            color: $white;
            @include fi(18);

            @include media_tel {
                @include fi(14);
            }

            &:before {
                content: '\e919';
            }

            &:hover {
                background-color: $color_grey_dark;
            }

            &:active {
                background-color: $color_black;
            }
        }
    }

    &.btn-wish {
        box-shadow: none !important;
        border: 2px solid $color_border;
        border-radius: 50%;
        color: $color_grey_light;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        width: 56px;
        @include fi(24);

        @include media_tel {
            @include fi(20);
            height: 48px;
            width: 48px;
        }

        &:before {
            @include koko;
            content: '\e915';
        }

        &:hover, &.active {
            border-color: $color_red;
            color: $color_red;
        }

        &.active {
            @include fi(26);

            @include media_tel {
                @include fi(22);
            }

            &:before {
                content: '\e906';
            }
        }
    }

    &.btn-orange {
        background-color: $color_primary;
        color: $color_black;

        &:hover {
            background-color: $color_primary_hover;
            color: $color_black;
        }

        &:focus {
            background-color: $color_primary_active;
            border-color: $color_primary_active;
            color: $color_black;

            span {
                color: inherit;
            }
        }
    }

    &.btn-green {
        background-color: $color_primary;
        color: $color_black;

        &:hover {
            background-color: $color_primary_hover;
        }
    }

    &.btn-blue {
        background-color: $blue1;
        color: $white;

        &:hover {

        }
    }

    &.btn-brownlight {
        background-color: $brown2;
        color: $color_black;

        &:hover {
            background-color: $brown4;
            color: $color_black;
        }
    }

    &.btn-black {
        background-color: $color-black;
        color: $white;

        &:hover {
            background-color: $white;
            color: $color-black;
        }
    }

    &.btn-transparent {
        background-color: transparent;
        color: $color_grey_light;

        &:hover {
            color: $red1;
        }
    }

    &.btn-transparent-white {
        background-color: $white;
    }

    &.btn-transparent-orange, &.btn-transparent-white {
        border: 1px solid $color_primary_active;
        color: $color_primary_active;

        &:hover {
            background-color: $color_primary_hover;
            border-color: $color_primary_hover;
            color: $color_black;
        }

        &:focus {
            background-color: $color_primary_active;
            border-color: $color_primary_active;
            color: $color_black;

            span {
                color: inherit;
            }
        }
    }

    &.btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-md {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 13px 30px;

        @include media_more_than_tel {
            font-size: 16px;
            padding-top: 18px;
            padding-bottom: 18px;
        }

        &.btn-transparent-orange {
            padding-top: 12px;
            padding-bottom: 12px;

            @include media_more_than_tel {
                padding-top: 17px;
                padding-bottom: 17px;
            }
        }
    }

    &.btn-lg {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        padding: 18px 30px;

        @include media_tel {
            font-size: 16px;
            padding: 14px 24px;
        }

        &.btn-icon {
            i {
                @include fi(14);
                margin-right: 10px;

                @include media_tel {
                    @include fi(12);
                }
            }
        }
    }

    &.btn-app {
        border-radius: 8px;
        padding: 14px 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            margin: -2px 8px 0 0;
            @include fi(20);

            &.ico-appstore {
                margin-right: 10px;
                font-size: 19px;
            }
        }

        span {
            @include ff(16);
        }
    }

    &[disabled] {
        opacity: 0.5;
        box-shadow: none !important;
    }

    &.btn-koko {
        color: $color_black;
        background-color: $color_primary;
        border: 2px solid $color_primary;
        border-radius: 12px;
        padding: 14px 20px;
        @include ff(16, 500);

        &.btn-lg {
            padding: 14px 80px;

            @include media_tablet_bootstrap {
                padding: 14px 40px;
            }
        }

        &.btn-small {
            padding: 7px 14px;
        }

        &.btn-xs {
            padding: 4px 10px;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $color_primary_hover;
                border-color: $color_primary_hover;
            }

            &:focus, &:active {
                background-color: $color_primary_active;
                border-color: $color_primary_active;
            }
        }

        .CurrencyIcon {
            font-weight: 700;
        }

        &.btn-icon {
            i {
                display: block;
                //height: 24px;
                @include fi(24);

                &.koko-down {
                    height: auto;
                    margin-top: 1px;
                    @include fi(12);

                    & + span {
                        margin-left: 10px;
                    }
                }

                &.koko-edit {
                    @include fi(20);
                }
            }

            i + span,
            span + i {
                margin-left: 8px;
            }
        }

        &.cart-sidebar__btn-checkout_disabled,
        &.ordering-page__total-btn_disabled,
        &[disabled],
        &.disabled {
            color: $color_grey_light !important;
            background-color: $color_border !important;
            cursor: not-allowed;
            opacity: 1;
        }

        &.btn-koko-grey {
            background-color: $color_default;
            border-color: $color_default;

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $color_primary_hover;
                    border-color: $color_primary_hover;
                }

                &:focus, &:active {
                    background-color: $color_primary_active;
                    border-color: $color_primary_active;
                }
            }
        }

        &.btn-koko-white {
            border-color: $color_border;
            background-color: $white;

            &-whole {
                border-color: $white;
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    border-color: $color_primary;
                    background-color: $color_primary_light;
                }
            }
        }

        &.btn-koko-transparent {
            border-color: $color_border;
            background-color: transparent;

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    border-color: $color_primary;
                    background-color: $color_primary_light;
                }
            }
        }

        &.btn-koko-black {
            border-color: $color_black;
            background-color: $color_black;
            color: $white;

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    border-color: $color_grey_dark;
                    background-color: $color_grey_dark;
                }
            }
        }

        &.btn-icon-extra {
            padding-left: 14px;
            padding-right: 14px;
            width: 56px;

            @include media_tel {
                padding: 10px 12px;
                width: 40px;
            }

            @include media_tel_xs {
                padding: 10px;
                width: auto;
            }

            i {
                @include media_tel {
                    //height: 16px;
                    @include fi(16);
                }
            }

            & > span {
                display: none;
            }

            &.btn-active {
                padding-left: 24px;
                padding-right: 24px;
                width: auto;

                & > span {
                    display: block;
                }
            }

            &:hover {
                .koko-wish {
                    color: $color_red;
                }
            }

            &.btn-on {
                background-color: $white;
                border-color: $color_primary_active;
                color: $color_primary_active;
            }

            .koko-left {
                font-size: 14px;
            }
        }

        &.btn-arrow {
            @include media_tel {
                border-radius: 50%;
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                span {
                    display: none;
                }

                &:before {
                    content: '\e921';
                    @include koko;
                    @include fi(12);
                }
            }
        }
    }
}
@import "_vars";
@import "_mix";
@import "responsive";

.owl-carousel {
    position: relative;
    touch-action: pan-y;

    .owl-stage-outer {
        @include media_tablet_vert_bootstrap {
            overflow: visible !important
        }
    }

    .owl-next, .owl-prev {
        width: 32px;
        height: 32px;
        background: $white !important;
        border: 1px solid $color_border !important;
        box-shadow: none;
        filter: none;
        border-radius: 50%;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        transition: all 0.15s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: $color_primary_hover !important;
            border-color: $color_primary_hover !important;
            transition: all 0.15s ease-in-out;
        }

        &:active {
            background: $color_primary_active !important;
            border-color: $color_primary_active !important;
            transition: all 0.15s ease-in-out;
        }

        &:before {
            content: '';
            color: $font_color;
            transition: all 0.15s ease-in-out;
            @include koko;
            @include fi(12);
        }

        &.disabled {
            background: $white !important;
            border-color: $color_border !important;
            cursor: default;
            transition: all 0.15s ease-in-out;

            &:before {
                color: $color_border !important;
                transition: all 0.15s ease-in-out;
            }
        }

        span {
            display: none
        }
    }

    .owl-prev {
        left: -16px;

        &:before {
            content: '\e917';
            margin-left: -1px;
        }
    }

    .owl-next {
        right: -16px;

        &:before {
            content: '\e916';
            margin-right: -1px;
        }
    }
}

.products-carousel {
    display: none;
    margin: 0;

    .owl-stage {
        display: flex;
        flex: 1 0 auto
    }

    .owl-next, .owl-prev {
        margin-top: 0;
        top: 150px
    }

    .owl-item {
        display: flex
    }

    .product-thumb {
        box-shadow: none;
        margin: 0
    }

    &_slide {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100%
    }
}

.popupCarousel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -99;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateY(50px);
    transition: .2s;

    &.popup-certificate {
        z-index: 2147483002;
    }

    &__block {
        z-index: 9999;
        visibility: visible
    }

    &__show {
        opacity: 1;
        transform: translateY(0);
        transition: .2s
    }

    &__owerlay {
        position: absolute;
        top: -50%;
        left: 0;
        width: 100%;
        height: 200%;
        z-index: -1;
        background-color: rgba(0, 0, 0, .7)
    }

    &__content {
        max-width: 1200px;
        margin: auto;
        width: 100%;
        position: relative;
    }

    &__close {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: -40px;
        background-image: url('../image/close-gray.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;

        &:hover {
            opacity: .6
        }

        @include media_tel {
            top: -35px;
            right: 0
        }
    }

    &__nav &__slide {
        background: #c9c9c9;
        color: $white;
        text-align: center;
        cursor: pointer
    }

    &__nav .current &__slide {
        background: #0c83e7
    }

    &__slide {
        &_pink .popup-answer-slide__header {
            background: #ffb1b1
        }

        &_green .popup-answer-slide__header {
            background: #c6da60
        }

        &_green .popup-answer-slide__title {
            color: #5b6b09
        }

        &_orange .popup-answer-slide__header {
            background: #ffdd95
        }

        &_orange .popup-answer-slide__title {
            color: #6c521a
        }

        &_blue .popup-answer-slide__header {
            background: #aef6fd
        }
    }
}